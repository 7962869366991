
import { defineComponent } from 'vue';
import prism from 'prismjs';
import clipboard from 'clipboard-js';
import 'prismjs/components/prism-haml';
import 'prismjs/themes/prism-okaidia.css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-shell-session';

interface Data {
  copyMessage: string;
}

export default defineComponent({
  props: {
    content: {
      type: String,
      required: true,
    },
    copyOption: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'language-haml',
    },
  },
  name: 'CodeSnippet',
  data(): Data {
    return {
      copyMessage: 'Copy',
    };
  },
  mounted() {
    prism.highlightAll();
  },
  watch: {
    async content() {
      await this.$nextTick();
      prism.highlightAll();
    },
  },
  methods: {
    async copySnippet() {
      clipboard.copy(this.content);

      this.copyMessage = 'Copied!';
      setTimeout(() => {
        this.copyMessage = 'Copy';
      }, 2000);
    },
  },
});
