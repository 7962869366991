
/* eslint-disable import/no-unresolved */
import { defineComponent } from 'vue';
import Header from '@/layout/Header.vue';
import Footer from '@/layout/Footer.vue';

export default defineComponent({
  name: 'Home',
  components: {
    Header,
    Footer,
  },
  mounted() {
    const isDarkMode = localStorage.getItem('isDarkMode');
    const isDarkModeBoolean = isDarkMode === 'true';
    this.$store.dispatch('setDarkMode', isDarkModeBoolean);
  },
});
