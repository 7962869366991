
import { defineComponent } from 'vue';
import NProgress from 'nprogress';

export default defineComponent({
  name: 'Header',
  computed: {
    isDarkMode() {
      return this.$store.state.isDarkMode;
    },
    currentRoute() {
      return this.$router.currentRoute.value;
    },
  },
  methods: {
    share(): void {
      window.open('https://twitter.com/intent/tweet?text=📣 Check out this Vue 3 component to quickly create an animation that'
      + ' shows an automatic count for any quantity with a specified duration, it can be used for counting up and down.'
      + '&size=large'
      + '&url=https://vue3autocounter.cristopherps.dev'
      + '&hashtags=vue3,webcomponent,ModoFuego🔥'
      + '&via=ps_cristopher', '_blank', 'width=500,height=300,scrollbars=NO,location=NO,titlebar=NO');
    },
    isSameOfCurrentRoute(routeName: string): boolean {
      return this.currentRoute.name === routeName;
    },
    toggleDarkMode(): void {
      NProgress.start();
      NProgress.set(0.8);
      this.$store.dispatch('setDarkMode', !this.isDarkMode);
      // window.location.reload();
      NProgress.done();
    },
    toggleSideBar(): void {
      this.$store.dispatch('toggleSideBar');
    },
  },
});
